
















import { Component, Vue, Prop } from 'vue-property-decorator'
import { StockIndex } from '../services/dataService'

@Component
export default class StockIndexBar extends Vue {
  @Prop() data: StockIndex[]
}
