export interface MenuTreeNode {
  label: string;
  path?: string;
  children?: Array<MenuTreeNode>;
}

export default [
  {
    label: 'Home',
    path: '/home'
  },
  {
    label: 'Portfolio',
    path: '/home/portfolio'
  },
  {
    label: 'Watchlist',
    path: '/home/watchlist'
  },
  {
    label: 'Alerts',
    path: '/home/alerts'
  },
  { label: 'Markets' },
  {
    label: 'To-Do',
    path: '/home/todo',
    children: []
  },
  {
    label: 'Historical Data',
    path: '/home/historical-data',
    children: []
  },
  {
    label: 'Analysis'
  },
  {
    label: 'Reports',
    children: [{
      label: 'P&L By Stock',
      path: '/home/report/stock-pl'
    }, {
      label: 'Portfolio Return',
      path: '/home/report/portfolio-return'
    }, {
      label: 'Stock Trades',
      path: '/home/report/stock-trades'
    }, {
      label: 'Option Trades',
      path: '/home/report/option-trades'
    }, {
      label: 'Dividends',
      path: '/home/report/dividends'
    }]
  },
  {
    label: 'Settings',
    children: [{
      label: 'User Settings',
      path: '/home/settings/user'
    }, {
      label: 'Trading Parameters',
      path: '/home/settings/trading'
    }, {
      label: 'Users',
      path: '/home/settings/account'
    }, {
      label: 'App Settings',
      path: '/home/settings/app'
    }, {
      label: 'Mail Settings',
      path: '/home/settings/mail'
    }, {
      label: 'Notifications',
      path: '/home/settings/notifications'
    }]
  },
  {
    label: 'Freebie Detector',
    children: [{
      label: 'List',
      path: '/home/FreebieDetector/List'
    }, {
      label: 'Logs',
      path: '/home/FreebieDetector/Log'
    }]
  }
]
