import firebase from 'firebase/app'
import 'firebase/messaging'
import memberService from '@/services/memberService'

const firebaseConfig = {
  apiKey: 'AIzaSyDxQ_xdWE-QFg_nW0SdxZXHmDsUKVIQSKs',
  authDomain: 'spearheadtradingsys.firebaseapp.com',
  projectId: 'spearheadtradingsys',
  storageBucket: 'spearheadtradingsys.appspot.com',
  messagingSenderId: '263669539805',
  appId: '1:263669539805:web:ee3a444493f693b04b8c34'
}

export default class FirebasePush {
  messaging: firebase.messaging.Messaging

  constructor () {
    console.log('initializeApp')
    // Initialize Firebase
    firebase.initializeApp(firebaseConfig)
    // Retrieve Firebase Messaging object.
    this.messaging = firebase.messaging()
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    this.messaging.onMessage((payload: any) => {
      console.log(payload)
      const not = new Notification(payload.notification.title, {
        body: payload.notification.body,
        icon: '/logo.png'
      })
    })
  }

  isTokenSentToServer () {
    return window.localStorage.getItem('sentToServer') === '1'
  }

  setTokenSentToServer (sent: boolean) {
    window.localStorage.setItem('sentToServer', sent ? '1' : '0')
  }

  // Send the registration token your application server, so that it can:
  // - send messages back to this app
  // - subscribe/unsubscribe the token from topics
  async sendTokenToServerAsync (currentToken: string) {
    const oldToken = localStorage.getItem('chromeToken')
    if (currentToken !== oldToken) { // maybe login as a new user, delete the old token
      // await this.messaging.deleteToken(currentToken)
      await memberService.updateAsync({ ChromeToken: currentToken })
      localStorage.setItem('chromeToken', currentToken)
    }
    console.log(currentToken)
    if (!this.isTokenSentToServer()) {
      console.log('Sending token to server...')
      // TODO(developer): Send the current token to your server.
      // await memberService.updateAsync({ ChromeToken: currentToken })
      this.setTokenSentToServer(true)
    } else {
      await memberService.updateAsync({ ChromeToken: currentToken })
      console.log('Token already sent to server so won\'t send it again ' +
        'unless it changes')
    }
  }

  // if account changed, should delete this token
  async deleteTokenAsync () {
    // Delete registration token.
    try {
      const currentToken = await this.messaging.getToken()
      await this.messaging.deleteToken(currentToken)
      console.log('Token deleted.')
      this.setTokenSentToServer(false)
      // Once token is deleted update UI.
      await this.getTokenAsync()
    } catch (e) {
      console.log('Unable to delete token. ', e)
      console.log('Error retrieving registration token. ', e)
    }
  }

  async getTokenAsync () {
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    try {
      const currentToken = await this.messaging.getToken({ vapidKey: 'BOGLN3WtTKzgJtqla20J8NjfR05C9o54hF4tpxNtOViomdWPcJC4jaj6xxlx3lEGIhB871H07g7_jqx4nFgSUdA' })
      if (currentToken) {
        await this.sendTokenToServerAsync(currentToken)
      } else {
        // Show permission request.
        console.log('No registration token available. Request permission to generate one.')
        // Show permission UI.
        this.setTokenSentToServer(false)
      }
    } catch (e) {
      console.log('An error occurred while retrieving token. ', e)
      this.setTokenSentToServer(false)
    }
  }

  async requestPermissionAsync () {
    console.log('Requesting permission...')
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      console.log('Notification permission granted.')
      // TODO(developer): Retrieve a registration token for use with FCM.
      // In many cases once an app has been granted notification permission,
      // it should update its UI reflecting this.
      await this.getTokenAsync()
    } else {
      console.log('Unable to get permission to notify.')
    }
  }
}
