



import { Component, Prop, Vue } from 'vue-property-decorator'
import { MenuTreeNode } from '@/services/menuData'

@Component
export default class MenuTree extends Vue {
  @Prop() data: MenuTreeNode[]
  isDev: boolean

  mounted () {
    this.isDev = process.env === 'development'
    // if (!this.data.find(p => p.label === 'Status')) {
    //   this.data.push({ label: 'Status', path: '/home/status' })
    // }
  }

  defaultProps = {
    children: 'children',
    label: 'label'
  }

  async handleNodeClick (data: MenuTreeNode) {
    if (data.path) {
      this.$emit('change', data)
      await this.$router.push(data.path)
    }
  }
}
