import serviceBase from '@/services/serviceBase'
import { ApiResult, ApiBooleanResult } from '@/services/data'

export interface NotificationItem {
  NotificationId: number;
  Created: string;
  Minutes: number;
  IsRead: boolean;
  Title: string;
  JsonData: string;
  Data: any;
  Content: string;
  Type?: string;
}

export interface NotificationGroup {
  Type: string;
  Items: Array<NotificationItem>;
}

export default {
  async getNotificationsAsync (IsRead?: boolean): Promise<ApiResult<NotificationItem[]>> {
    const res = await serviceBase.tryPostResult<NotificationItem[]>('/Notification/getNotifications', {
      IsRead
    })
    if (res.Result) {
      res.Result.forEach(p => {
        if (p.JsonData) {
          p.Data = Object.freeze(JSON.parse(p.JsonData))
          p.JsonData = null
        }
      })
    }
    return res
  },
  async markAllAsReadAsync (): Promise<ApiBooleanResult> {
    return await serviceBase.tryPostBooleanResult('/Notification/markAllAsRead')
  }
}
