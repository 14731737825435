


















































































































import { Component, Vue } from 'vue-property-decorator'
import MenuTree from '@/components/MenuTree.vue'
import Notifications from '@/components/Notifications.vue'
import dataService, { InitData } from '../services/dataService'
import memberService from '../services/memberService'
import store from '../store'
import ws, { SocketMsg } from '../services/socket'
import { Route } from 'vue-router'
import Menus, { MenuTreeNode } from '@/services/menuData'
import StockIndexBar from '@/components/StockIndexBar.vue'
import FirebasePush from '../services/firebasePush'
import symbolService from '@/services/symbolService'

let isHomePage = false
@Component({
  components: {
    menuTree: MenuTree,
    notifications: Notifications,
    StockIndexBar
  }
})
export default class Home extends Vue {
  initData: InitData = {
    Notification: { Count: 0 },
    StockIndex: []
  }

  showMenu = true
  isHome = isHomePage
  msg = ''
  menus: MenuTreeNode[] = Menus
  ibkr: { Acc: string; IsConnected: boolean; UsedTickers: number } = { Acc: '', IsConnected: false, UsedTickers: 0 }
  futu: { Acc: string; IsConnected: boolean; UsedTickers: number } = { Acc: '', IsConnected: false, UsedTickers: 0 }
  agent: { Connected: boolean; GatewayConnected: boolean; DefaultGateway: string; PortfolioGatewayConnected: boolean; SameAcc: boolean } = {
    Connected: false,
    GatewayConnected: false,
    DefaultGateway: null,
    PortfolioGatewayConnected: false,
    SameAcc: true
  }

  currentRouteName: string
  runningInterval: number
  notVisible = false

  get ibkrStatusDesc () {
    if (this.ibkr.IsConnected) {
      return (this.ibkr.Acc ?? 'Server gateway disconnected') + '(' + this.ibkr.UsedTickers + '/100)'
    }
    return 'IBKR Disconnected'
  }

  get futuStatusDesc () {
    if (this.futu.IsConnected) {
      return (this.futu.Acc ?? 'Server gateway disconnected') + '(' + this.futu.UsedTickers + '/300)'
    }
    return 'FUTU Disconnected'
  }

  async mounted () {
    this.initSocket()
    if (this.runningInterval) {
      clearInterval(this.runningInterval)
    }
    if (this.runningInterval) {
      clearInterval(this.runningInterval)
    }
    this.runningInterval = setInterval(() => {
      ws.Server.sendDirectMsg('sendPing', '')
      ws.Local.sendDirectMsg('reqStatus', '')
      if (this.$router.currentRoute) {
        if (this.currentRouteName !== this.$router.currentRoute.path) {
          this.currentRouteName = this.$router.currentRoute.path
          if (this.currentRouteName !== '/home/watchlist' &&
            this.currentRouteName !== '/home/portfolio' &&
            this.currentRouteName !== '/home/chart' &&
            this.currentRouteName !== '/home/news' &&
            this.currentRouteName !== '/home/options' &&
            this.currentRouteName !== '/home/FreebieDetector/List' &&
            this.currentRouteName !== '/home/FreebieDetector/Log') {
            console.log('--------send context-----------')
            ws.Server.sendDirectMsg('sendContext', { page: this.$router.currentRoute.name, symbols: [] })
          }
        }
      }
    }, 10000)
    window.addEventListener('beforeunload', (evt: Event) => {
      ws.Server.close()
      ws.Local.close()
    })
    await this.loadNotifications()
    const fp = new FirebasePush()
    await fp.requestPermissionAsync()
  }

  async loadNotifications () {
    const res = await dataService.getInitData()
    this.initData = res.Result
    if (res.Result) {
      this.notificationCount = res.Result.Notification.Count
    }
  }

  get userEmail () {
    return this.$store.state.user.email
  }

  get mainStatus () {
    if (this.agent.Connected && this.agent.GatewayConnected && this.agent.SameAcc) {
      return ''
    }
    if (!this.agent.Connected) {
      return 'err'
    }
    return 'warn'
  }

  get mainError () {
    if (!this.agent.Connected) {
      return 'Agent Disconnected'
    }
    if (!this.agent.GatewayConnected) {
      return 'Agent Gateway Disconnected'
    }
    if (!this.agent.SameAcc) {
      return 'Inconsistent Account'
    }
    return ''
  }

  get notificationCount () {
    return this.$store.state.notificationCount
  }

  set notificationCount (v) {
    store.commit('setNotificationCount', v)
  }

  beforeRouteEnter (to: Route, from: Route, next: () => void) {
    isHomePage = (to.fullPath === '/')
    next()
  }

  beforeRouteUpdate (to: Route, from: Route, next: () => void) {
    this.isHome = (to.name === 'home')
    next()
  }

  destroyed () {
    ws.Server.removeMessageCallBack(this.msgCallBack)
    ws.Local.removeMessageCallBack(this.msgCallBack)
    console.log('home destroyed')
  }

  msgCallBack (msg: SocketMsg) {
    if (msg.Action === 'resConn') {
      if (msg.Content.IBKR) {
        this.ibkr = msg.Content.IBKR
      }
      if (msg.Content.FUTU) {
        this.futu = msg.Content.FUTU
      }
    } else if (msg.Action === 'resAgentContext') {
      store.state.user.accNo = msg.Content.account
      store.state.user.agentId = msg.Content.agentId
      store.commit('setUser', store.state.user)
    } else if (msg.Action === 'resPing') {
      if (msg.Content.IBKR) {
        this.ibkr = msg.Content.IBKR
      }
      if (msg.Content.FUTU) {
        this.futu = msg.Content.FUTU
      }
    } else if (msg.Action === 'resNot') {
      this.loadNotifications()
    } else if (msg.Action === 'resStkIndex') {
      this.initData.StockIndex = msg.Content
    } else if (msg.Action === 'resAgentStatus') {
      this.agent.Connected = true
      this.agent.GatewayConnected = msg.Content.Gateway
      this.agent.PortfolioGatewayConnected = msg.Content.PortfolioGateway
      this.agent.DefaultGateway = msg.Content.GatewayName
      this.agent.SameAcc = (msg.Content.LoginId === store.state.user.email)
      store.commit('setAgent', this.agent)
    }
    console.log(msg)
  }

  initSocket () {
    ws.Server.init()
    ws.Local.init()
    ws.Local.OnError = () => {
      this.agent.Connected = false
      this.agent.PortfolioGatewayConnected = false
      this.agent.GatewayConnected = false
      store.commit('setAgent', this.agent)
    }
    ws.Server.addMessageCallBack(this.msgCallBack)
    ws.Local.addMessageCallBack(this.msgCallBack)
  }

  closeSocket () {
    ws.Server.close()
    ws.Local.OnError = null
    ws.Local.close()
    this.msg = 'Closed'
  }

  async switchNotification () {
    console.log('......')
  }

  async sendTestMsg () {
    await memberService.sendTestMsgAsync(200)
  }

  async sendTestAlert () {
    await memberService.sendTestMsgAsync(16)
  }

  async updateFromYahoo () {
    const res = await symbolService.updateFromYahooAsync()
    if (res.Error) {
      await this.$alert(res.Error)
    }
  }

  logout () {
    store.commit('setUser', null)
    localStorage.removeItem('jwt')
    this.closeSocket()
    this.$router.push('/')
  }
}
