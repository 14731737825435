import serviceBase from '@/services/serviceBase'
import { ApiResult } from '@/services/data'

export interface StockIndex {
  Name: string;
  Index: number;
  Changed: number;
  ChangeRate: number;
}

export interface NotificationCount {
  Count: number;
}

export interface InitData {
  StockIndex: StockIndex[];
  Notification: NotificationCount;
}

export default {
  async getInitData (): Promise<ApiResult<InitData>> {
    return await serviceBase.tryPostResult<InitData>('/data/getInitData')
  }
}
