






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import notificationService, { NotificationItem } from '../services/notificationService'
import utility from '@/common/utility'
import store from '../store'

@Component
export default class Notifications extends Vue {
  notificationArr: Array<NotificationItem> = []

  async mounted () {
    const res = await notificationService.getNotificationsAsync(false)
    const result = res.Result
    if (result) {
      this.notificationArr = utility.freezeArrayItems(result)
    }
  }

  async markAllAsRead () {
    const res = await notificationService.markAllAsReadAsync()
    const result = res.Result
    if (result) {
      this.notificationArr = []
      store.commit('setNotificationCount', 0)
    }
  }
}
